import {
  filterByCapability,
  transformedItemsBySpecificCapabilities
} from '@/shared/utils/capabilityUtils'

import { getLocalRetailItems } from './constants/retail'
import { getLocalSpItems } from './constants/servicepoint'
import { addSidemenuMeta } from './utils'

export const getRetailItems = async (permission, market) => {
  let retailItems = getLocalRetailItems()
  const isProduction = process.env.NODE_ENV === 'production'
  retailItems = retailItems
    .filter((item) => item.meta?.permission <= permission)
    .filter(
      (item) => !item.meta?.markets || item.meta?.markets.includes(market)
    )
    .filter((item) => !(isProduction && item.meta?.id === 'testool'))
  return addSidemenuMeta(retailItems)
}

export const filterSpItems = async (menuItems, capability) => {
  let filteredItems = filterByCapability(menuItems, capability)
  filteredItems = transformedItemsBySpecificCapabilities(
    filteredItems,
    capability
  )
  return filteredItems
}

export const getSpItems = async (capability) => {
  const spItems = await filterSpItems(getLocalSpItems(), capability)
  return addSidemenuMeta(spItems)
}
