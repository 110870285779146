<template>
  <!-- mobile -->
  <div
    :class="[
      mobileCollapsed ? 'side-menu side-menu--collapsed' : 'side-menu',
      'mobile-menu'
    ]"
  >
    <div class="side-menu__content">
      <div class="side-menu__item side-menu__item--toggle">
        <div class="side-menu__item__col">
          <div class="hamburger-container">
            <button
              class="hamburger hamburger--squeeze"
              :class="[!mobileCollapsed ? 'is-active' : '']"
              type="button"
              @click="toggleMobileSidebar"
            >
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <span id="menuMobileToggleText" class="menu-item-text">{{
            menuText
          }}</span>
        </div>
      </div>
      <div class="menu-items-container">
        <div
          v-for="menuItem in items"
          :key="menuItem.meta.id"
          :class="[
            { active: isMenuActive(menuItem) },
            { 'menu-top-level--sub-menu': menuItem.children },
            { 'menu-top-level--regular': !menuItem.children },
            'menu-top-level'
          ]"
        >
          <component
            :is="componentFor(menuItem)"
            :to="menuItem.path || ''"
            :href="menuItem.url || ''"
            class="side-menu__item"
            @click="toggleChildrenDisplay(menuItem)"
          >
            <div class="side-menu__item__col">
              <pn-icon
                :symbol="menuItem.meta.icon"
                color="blue700"
                class="menu-item-icon"
              />
              <span class="menu-item-text">{{ menuItem.name }}</span>
            </div>
            <div
              v-if="menuItem.children"
              class="side-menu__item__col side-menu__item__col--right"
            >
              <div
                :class="[toggledItems[menuItem.meta.id] ? 'opened' : 'closed']"
              >
                <pn-icon icon-only :symbol="angle_down" color="blue700" />
              </div>
            </div>
          </component>
          <div v-if="toggledItems[menuItem.meta.id]">
            <div
              v-for="childItem in menuItem.children"
              :key="childItem.meta.id"
              :class="[
                { active: isMenuActive(childItem) },
                'menu-top-level menu-top-level--regular'
              ]"
            >
              <component
                :is="componentFor(childItem)"
                :to="childItem.path || ''"
                :href="childItem.url || ''"
                class="side-menu__item"
              >
                <div class="side-menu-item__col side-menu__item__col--child">
                  <span class="menu-item-text">{{ childItem.name }}</span>
                </div>
              </component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- desktop -->
  <div
    ref="sidebar"
    :class="[
      desktopCollapsed ? 'side-menu side-menu--collapsed' : 'side-menu',
      'desktop-menu'
    ]"
  >
    <div class="side-menu__content">
      <div class="side-menu__item side-menu__item--toggle">
        <div class="side-menu__item__col">
          <div class="menu-icon">
            <pn-icon
              class="toggle-button"
              :symbol="bars"
              small
              color="blue700"
              @click="toggleDesktopSidebar"
            />
          </div>
          <span class="menu-item-text">{{ menuText }}</span>
        </div>
      </div>
      <div class="menu-items-container">
        <div
          v-for="menuItem in items"
          :key="menuItem.meta.id"
          :class="[
            { active: isMenuActive(menuItem) },
            { 'menu-top-level--sub-menu': menuItem.children },
            { 'menu-top-level--regular': !menuItem.children },
            'menu-top-level'
          ]"
        >
          <component
            :is="componentFor(menuItem)"
            :to="menuItem.path ? menuItem.path : ''"
            :href="menuItem.url ? menuItem.url : ''"
            class="side-menu__item"
            @click="toggleChildrenDisplay(menuItem)"
          >
            <div class="side-menu__item__col">
              <pn-icon
                :symbol="menuItem.meta.icon"
                color="blue700"
                class="menu-item-icon"
              />
              <span class="menu-item-text">{{ menuItem.name }}</span>
            </div>
            <div
              v-if="menuItem.children"
              class="side-menu__item__col side-menu__item__col--right"
            >
              <div
                :class="[toggledItems[menuItem.meta.id] ? 'opened' : 'closed']"
              >
                <pn-icon icon-only :symbol="angle_down" color="blue700" />
              </div>
            </div>
          </component>
          <div v-if="toggledItems[menuItem.meta.id]">
            <div
              v-for="childItem in menuItem.children"
              :key="childItem.meta.id"
              :class="[
                { active: isMenuActive(childItem) },
                'menu-top-level menu-top-level--regular'
              ]"
            >
              <component
                :is="componentFor(childItem)"
                :to="childItem.path ? childItem.path : ''"
                :href="childItem.url ? childItem.url : ''"
                class="side-menu__item"
              >
                <div class="side-menu-item__col side-menu__item__col--child">
                  <span class="menu-item-text">{{ childItem.name }}</span>
                </div>
              </component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { angle_down, bars } from '@/shared/constants/icons.js';
import { useFeatureFilter } from './useFeatureFilter.js';

export default {
  name: 'SideMenu',
  props: {
    activeMenu: {
      type: String,
      default() {
        return ''
      }
    },
    openedItem: {
      type: String,
      default: ''
    }
  },
  emits: ['toggleMobileSidebar', 'toggleDesktopSidebar'],
  setup() {
    const { filteredFeatures } = useFeatureFilter()
    return {
      filteredFeatures
    }
  },
  data() {
    return {
      angle_down,
      bars,
      items: [],
      showMobileMenu: false,
      windowWidth: window.innerWidth,
      mobileCollapsed: true, // collapsed is default
      desktopCollapsed: false,
      toggledItems: {
        // add meta.id here for menu items with children
        messageweb: false
      }
    }
  },

  computed: {
    isMobile() {
      return this.windowWidth < 1024
    },

    menuIcon() {
      return this.isMobile && !this.desktopCollapsed ? 'close' : 'bars'
    },

    menuText() {
      return this.isMobile && !this.mobileCollapsed ? 'Close' : 'Menu'
    }
  },
  watch: {
    filteredFeatures(val) {
      this.items = val
    }
  },

  mounted() {
    this.init()
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    window.addEventListener('scroll', this.handleScroll)
  },

  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    async init() {
      if (
        Object.prototype.hasOwnProperty.call(this.toggledItems, this.openedItem)
      ) {
        this.toggledItems[this.openedItem] = true
      }
    },
    toggleDesktopSidebar() {
      this.desktopCollapsed = !this.desktopCollapsed
      this.$emit('toggleDesktopSidebar', this.desktopCollapsed)
    },
    toggleMobileSidebar() {
      this.mobileCollapsed = !this.mobileCollapsed
      this.$emit('toggleMobileSidebar', this.mobileCollapsed)
    },
    isMenuActive(menuItem) {
      return (
        menuItem.name?.toLowerCase() === this.activeMenu.toLowerCase() || false
      )
    },
    componentFor(menuItem) {
      if (menuItem.children) {
        return 'div'
      }

      const isFromMessages =
        window.location.href.includes('/messages') &&
        (menuItem.name === 'Inbox' ||
          menuItem.name === 'Sent' ||
          menuItem.name === 'Archived')
      const isOnDashboard =
        window.location.href.includes('/dashboard') &&
        (menuItem.name === 'Inbox' ||
          menuItem.name === 'Sent' ||
          menuItem.name === 'Archived')
      const isUsingVueRoutePath =
        menuItem?.path !== undefined && menuItem?.path !== null

      if (isOnDashboard) {
        return 'a'
      }

      if (isFromMessages || isUsingVueRoutePath) {
        return 'router-link'
      }

      return 'a'
    },
    handleScroll() {
      // adjust position when scroll reaches threshold
      if (window.scrollY > 0) {
        if (this.$refs.sidebar) {
          this.$refs.sidebar.style.top = '0'
        }
        if (window.location.href.includes('/cash-delivery')) { //RBO-1155 - Scalability on Topbar for Cash Delivery Screen with Shapeshifter
          this.$refs.sidebar.style.top = '56px'
        }
      } else {
        if (this.$refs.sidebar) {
          this.$refs.sidebar.style.top = '56px'
        }
      }
    },
    toggleChildrenDisplay(menuItem) {
      if (menuItem.children) {
        this.toggledItems[menuItem.meta.id] =
          !this.toggledItems[menuItem.meta.id]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './hamburger.scss';

.mobile-menu {
  display: block;
  padding: 1rem 0;
}

.desktop-menu {
  display: none;
}

.side-menu {
  width: 100%;
  box-sizing: border-box;
  color: $blue700;
  z-index: 3;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    text-decoration: none;

    &__col {
      display: flex;
      align-items: center;
      gap: 2rem;

      &--child {
        padding-left: 39px;
      }

      &--right {
        padding-right: 0.35em;
      }
    }

    &--toggle {
      padding-left: 1rem;
      gap: 1rem;
      cursor: unset;
    }
  }

  &--collapsed {
    height: 3.5em;
    box-shadow: 0px 9px 31px -8px rgb(0 0 0 / 25%);

    .menu-items-container {
      display: none;
    }
  }
}

.menu-items-container {
  overflow: auto;
  height: calc(100vh - 120px);
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: $gray200;
    border-radius: 41px;
  }
}

.hamburger-container {
  position: relative;
  width: 1.1125em;
  height: 1.5em;
  background-color: $white;
}

.menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $white;
  cursor: pointer;
}

.menu-item-text {
  color: $blue700;
  font-size: 14px;
}

.menu-child-level {
  .side-menu__item__col {
    padding-left: 0.35em;
  }
}

.menu-top-level {
  border-left: 0.35em solid transparent;

  &.active {
    background-color: $blue50;
    border-color: $blue700;
  }

  &--regular {
    &:hover {
      background-color: $blue50;
      border-color: $blue700;
    }
  }

  &--sub-menu {
    border-left: none;

    .side-menu__item {
      padding-left: 16px;
    }
    &:hover {
      background-color: unset;
    }
  }
}

.opened {
  transition: all 0.4s ease;
  transform: rotate(-180deg);
}

.closed {
  transition: all 0.4s ease;
  transform: rotate(0);
}

@media (min-width: 1024px) {
  .mobile-menu {
    display: none;
  }

  .desktop-menu {
    display: block;
  }

  .side-menu {
    min-height: 100vh;
    width: 19em; /* change this with JavaScript */
    position: fixed;
    z-index: 3; /* Stay on top */
    top: 56px;
    left: 0;
    background-color: $white; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.2s ease-in-out;
    border-right: 1px solid $gray200;

    &__item {
      &--toggle {
        padding-top: 4rem;
        padding-left: 1rem;
        padding-bottom: 4rem;
        gap: 1rem;
        cursor: unset;
      }
    }

    &--collapsed {
      width: 3.8em;
      box-shadow: none;
      min-height: 100vh;

      .menu-items-container {
        display: block;
      }

      .menu-item-text {
        display: none;
      }

      .side-menu__item__col--right {
        display: none;
      }
    }
  }

  .menu-icon {
    background-color: $gray50;
  }
}
</style>
